.form-control, .form-select {
    border: 0;
    border-bottom: 1px solid #cfdefc;
    border-radius: 0;
    padding: 7px 0;
}
.form-control.nobg, .form-select.nobg {
    background-color: transparent;
}
.textarea {
    border: 1px solid #cfdefc;
    border-radius: 4px;
    padding: 1rem;
    resize: none;
}
.flickity-page-dots .dot {
    background: #ccc;
}
.spotlight-painting-service .form-wrap .wrap .form-control {
    font-size: .9rem;
    margin-bottom: .2rem;
    padding: .6 0 .3rem;
}
.spotlight-painting-service .form-wrap .wrap .terms-and-conditions p {
    font-size: 9px;
    margin-bottom: .2rem;
}
.spotlight-painting-service .form-wrap .wrap .terms-and-conditions p:last-child {
    margin-bottom: .5rem;
}
.spotlight-painting-service .form-wrap .wrap .terms-and-conditions p a {
    color: #e7194f;
}
#msform {
    margin: 20px auto;
    position: relative;
    text-align: center;
}
#msform fieldset {
    background: #fff;
    border: 0;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 20px 30px;
    position: relative;
    width: 100%}
#msform fieldset:not(:first-of-type) {
    display: none;
}
#msform input, #msform textarea {
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    color: #2c3e50;
    font-family: montserrat;
    font-size: 18px;
    margin-bottom: 10px;
    padding: 15px;
    width: 100%}
#msform .action-button {
    background: #27ae60;
    border: 0;
    border-radius: 7px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: bolder;
    margin: 10px 5px;
    padding: 14px;
    text-decoration: none;
    width: 175px;
        font-family:  'Montserrat';
}
* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
*, body {
    margin: 0;
    padding: 0;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
    color: #222;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    text-rendering: optimizeLegibility;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
h1, h2, h3, h4, h5, h6 {
    color: #000000;
    font-weight: 700;
}
.lock-scroll {
    display: fixed;
    overflow: hidden;
}
.container {
    max-width: 98%;
     margin-bottom:  3rem;
}
.grey-bg {
    background: #f6f6f6;
}
.text-light * {
    color: #fff;
}
.text-dark * {
    color: #00205b;
}
.row-cols-lg-2>* {
    margin: 0 auto;
}
@media only screen and (max-width:767px) {
    .container {
    max-width: 100%}
}section {
    background: #fff;
    padding: 5rem 0;
}
@media(max-width:767px) {
    section {
    padding: 3rem 0;
}
}section.grey-bg {
    background: #f6f6f6;
}
.section-header {
    margin-bottom: 3rem;
    position: relative;
}
.section-header .tags-wrap {
    margin-bottom: .5rem;
}
.section-header .tags-wrap ul {
    display: flex;
    justify-content: center;
}
.section-header .tags-wrap ul li {
    background: #eee;
    border-radius: 6px;
    color: #00205b;
    font-size: 12px;
    font-weight: 700;
    margin: 0 .5rem;
    padding: 3px 10px;
}
.section-header .heading {
    font-size: 35px;
}
.section-header p {
    font-family:  'Montserrat',  sans-serif;
    ;
    font-weight: 500;
    margin: 0 auto;
    max-width: 65%}
@media(max-width:767px) {
    .section-header p {
        font-size: 19px;
    max-width: 100%;
    padding:  21px;
}
.spotlight-painting-service .form-wrap .wrap h3 {
    font-size: 18px;
}
}
.section-header .sort-by-wrap {
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}
@media(max-width:767px) {
    .section-header .sort-by-wrap {
    position: static;
    top: -20%}
}.flickity-prev-next-button {
    border: 2px solid transparent;
    box-shadow: none;
    height: 30px;
    transition: all .3s ease-in-out;
    width: 30px;
}
.flickity-prev-next-button:focus {
    box-shadow: none;
}
@media(max-width:767px) {
    .flickity-prev-next-button {
    display: none;
}
}.flickity-prev-next-button.next {
    right: -30px;
}
.flickity-prev-next-button.previous {
    left: -30px;
}
.flickity-prev-next-button:hover {
    border-color: #8b8b8b;
}
.flickity-prev-next-button:hover svg path {
    fill: #e7194f;
}
.flickity-prev-next-button svg path {
    fill: #8b8b8b;
}
.arrow-on-top .flickity-prev-next-button {
    right: .5rem;
    top: -4rem;
}
.arrow-on-top .flickity-prev-next-button.previous {
    left: auto;
    right: calc(1rem + 30px);
}
.arrow-on-top.arrow-near .flickity-prev-next-button {
    top: -1rem;
}
.arrow-on-top.arrow-medium-near .flickity-prev-next-button {
    top: -2.5rem;
}
.flickity-page-dots {
    bottom: -40px;
}
.flickity-page-dots .dot {
    background: rgba(0, 0, 0, .2);
    height: 6px;
    opacity: 1;
    width: 6px;
}
.flickity-page-dots .dot.is-selected {
    background: #e7194f;
}
.flickity-page-dots .dot:first-child:last-child {
    opacity: 0;
}
@media(max-width:767px) {
    .scrollable-on-mobile {
    overflow: auto;
    width: 100%}
.scrollable-on-mobile>div {
    width: 1000px;
}
.scrollable-on-mobile .row {
    flex-wrap: nowrap;
}
}.flickity-enabled {
    position: relative;
}
.flickity-enabled:focus {
    outline: none;
}
.flickity-viewport {
    height: 100%;
    overflow: hidden;
    position: relative;
}
.flickity-slider {
    height: 100%;
    position: absolute;
    width: 100%}
.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: grabbing;
}
.flickity-button {
    background: hsla(0, 0%, 100%, .75);
    border: none;
    color: #333;
    position: absolute;
}
.flickity-button:hover {
    background: #fff;
    cursor: pointer;
}
.flickity-button:focus {
    box-shadow: 0 0 0 5px #19f;
    outline: none;
}
.flickity-button:active {
    opacity: .6;
}
.flickity-button:disabled {
    cursor: auto;
    opacity: .3;
    pointer-events: none;
}
.flickity-button-icon {
    fill: currentColor;
}
.flickity-prev-next-button {
    border-radius: 50%;
    height: 44px;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
}
.flickity-prev-next-button.previous {
    left: 10px;
}
.flickity-prev-next-button.next {
    right: 10px;
}
.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
    left: 10px;
    right: auto;
}
.flickity-prev-next-button .flickity-button-icon {
    height: 60%;
    left: 20%;
    position: absolute;
    top: 20%;
    width: 60%}
.flickity-page-dots {
    bottom: -25px;
    line-height: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%}
.flickity-rtl .flickity-page-dots {
    direction: rtl;
}
.flickity-page-dots .dot {
    background: #333;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 10px;
    margin: 0 8px;
    opacity: .25;
    width: 10px;
}
.flickity-page-dots .dot.is-selected {
    opacity: 1;
}
.main-footer {
    background: #000000;
    color: #fff;
    margin-top: 4rem;
    padding: 2rem 0 0;
}
.main-footer .logo {
    margin-bottom: 45px;
    width: 250px;
}
@media(max-width:767px) {
    .main-footer .logo {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 70%}
}.main-footer .social-links-wrap {
    margin-bottom: 45px;
}
@media(max-width:767px) {
    .main-footer .social-links-wrap {
    margin-bottom: 20px;
}
}.main-footer .social-links-wrap .label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: .5rem;
}
.main-footer .social-links-wrap ul {
    display: flex;
}
@media(max-width:767px) {
    .main-footer .social-links-wrap ul {
    justify-content: center;
}
}
.main-footer .social-links-wrap ul li {
    width: 22px;
}
.main-footer .social-links-wrap ul li a {
    text-decoration: none;
}
.main-footer .social-links-wrap ul li a svg {
    fill: #e7194f;
    width: 100%}
.main-footer .head {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1rem;
    position: relative;
}
@media(max-width:767px) {
    .main-footer .head:after {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    content: "";
    height: 8px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: rotate(-45deg) translate(-5px, -12px);
    transform-origin: center;
    width: 8px;
}
.main-footer .head.active:after {
    border-color: #fff;
    border-style: solid;
    border-width: 2px 2px 0 0;
    transform: rotate(-45deg) translate(-7px, -10px);
}
.main-footer .head {
    border-top: 1px solid rgba(204, 222, 255, .26);
    font-size: 22px;
    margin: 0;
    padding: 1rem 0;
}
}.main-footer .links {
    font-size: 12px;
}
@media(max-width:767px) {
    .main-footer .links {
    display: none;
}
}.main-footer .links a {
    color: #fff;
    display: block;
    margin-bottom: 1rem;
    text-decoration: none;
}
.main-footer .subscribe-wrap {
    border-bottom: 1px solid hsla(0, 0%, 100%, .26);
    margin-bottom: 4rem;
    padding: 0 0 2.5rem;
}
@media(max-width:767px) {
    .main-footer .subscribe-wrap {
    margin-bottom: 1rem;
}
}.main-footer .bottom {
    background: #222;
    color: #fff;
    font-size: 12px;
    margin-top: 3rem;
    padding: 1rem 0;
}
@media(max-width:767px) {
    .main-footer .bottom {
    margin-top: 2rem;
    padding-bottom: 110px;
}
}.main-footer .bottom .bottom-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.main-footer .bottom .bottom-links a {
    color: #fff;
    margin-right: 1.5rem;
    text-decoration: none;
}
@media(max-width:767px) {
    .main-footer .bottom .bottom-links a {
    margin: 0 .75rem .5rem;
}
}.main-footer .row-cols-lg-5 {
    justify-content: space-evenly;
}
@media(max-width:1024px) {
    .main-footer .row-cols-lg-5 {
    justify-content: flex-start;
}
}.main-footer .row-cols-lg-5 .col {
    width: 18%}
@media(max-width:1024px) {
    .main-footer .row-cols-lg-5 .col {
    margin-bottom: 2rem;
    width: 33%!important;
}
}@media(max-width:767px) {
    .main-footer .row-cols-lg-5 .col {
    margin-bottom: 0;
    width: 100%!important;
}
}.main-footer .row-cols-lg-5 .col.seprate-links {
    width: 28%}
.main-footer .row-cols-lg-5 .col.seprate-links .links {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
}
@media(max-width:767px) {
    .main-footer .row-cols-lg-5 .col.seprate-links .links {
    display: none;
}
}.four-grid-carousel {
    margin-left: -.75rem;
    margin-right: -.75rem;
}
@media(max-width:767px) {
    .four-grid-carousel {
    margin-bottom: 3rem;
}
}.four-grid-carousel .slides {
    width: 25%}
@media(max-width:767px) {
}
.four-grid-carousel .wrap {
    padding: 0 .75rem;
    position: relative;
}
.four-grid-carousel .wrap .image {
    border-radius: 4px;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.four-grid-carousel .wrap .image:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, .4), transparent 50%);
    border-radius: 4px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.four-grid-carousel .wrap .image img {
    transition: all .3s ease-in-out;
}
.four-grid-carousel .wrap:hover .image img {
    transform: scale(1.05);
}
.four-grid-carousel .wrap .content {
    align-content: flex-end;
    align-items: flex-end;
    bottom: 0;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    left: 0;
    padding: 2rem;
    position: absolute;
    width: 100%;
    z-index: 2;
}
.four-grid-carousel .wrap .content .title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #fff;
    display: -webkit-box;
    font-size: 30px;
    margin-bottom: 1.5rem;
    min-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%}
@media(max-width:767px) {
    .four-grid-carousel .wrap .content .title {
    font-size: 26px;
}
}.four-grid-carousel .wrap .content a {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}
.four-grid-carousel .wrap .content a i {
    font-size: .8rem;
}
@media(min-width:960px)and (max-width:1024px) {
    .prod_listing_container .row-cols-lg-3>* {
    width: 50%}
}.sticky-panel-mobile {
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 80px;
    bottom: 1rem;
    box-shadow: 0 -10px 40px rgba(34, 34, 34, .2);
    display: none;
    left: 5%;
    padding: 0 20px;
    position: fixed;
    width: 90%;
    z-index: 10;
}
@media(max-width:767px) {
    .sticky-panel-mobile {
    display: block;
}
}.sticky-panel-mobile ul {
    display: flex;
    width: 100%}
.sticky-panel-mobile ul li {
    border-right: 1px solid rgba(34, 34, 34, .1);
    padding: 1rem 0 .5rem;
    text-align: center;
    width: 33.33%}
.sticky-panel-mobile ul li:last-child {
    border-right: 0;
}
.sticky-panel-mobile ul li .icon {
    height: 30px;
    margin-bottom: 5px;
    text-align: center;
}
.sticky-panel-mobile ul li .icon img {
    width: 30px;
}
.sticky-panel-mobile ul li .label {
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
}
.why-us-boxes-wrap {
    padding: 0 10%}
@media(max-width:767px) {
    .why-us-boxes-wrap {
    padding: 0;
}
}.why-us-boxes-wrap .wrap {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 32, 91, .1);
    padding: 1.5rem;
    text-align: center;
}
.why-us-boxes-wrap .wrap .icon {
    margin-bottom: 1rem;
    text-align: center;
}
.why-us-boxes-wrap .wrap .icon img {
    margin: 0 auto;
    width: 60px;
}
.why-us-boxes-wrap .wrap .title {
    color: #00205b;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1rem;
}
.painting-service-header {
    background: #fff;
    padding: 0.5rem 0;
}
.painting-service-header .logo-wrap {
    width: 155px;
}
.spotlight-painting-service {
    position: relative;
}
.spotlight-painting-service .image-wrap img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%}
.spotlight-painting-service .highlights-wrap {
    left: 40%;
    position: absolute;
    top: 6rem;
    width: 300px;
}
@media(min-width:768px)and (max-width:959px) {
    .spotlight-painting-service .highlights-wrap {
    left: auto;
    right: 0;
}
}@media(max-width:767px) {
    .spotlight-painting-service .highlights-wrap {
    left: auto;
    right: .75rem;
    top: 3rem;
    width: 40%}
}.spotlight-painting-service .form-wrap {
    position: absolute;
    right: 2.5%;
    top: 2rem;
    width: 383px;
}
/* @media(min-width:768px)and (max-width:959px) {
    .spotlight-painting-service .form-wrap {
    background: #fff;
    left: 0;
    margin: -50px auto 0;
    position: relative;
    right: 0;
    top: auto;
    width: 75%}
} */
@media(max-width:799px) { /* changed from 767 px*/
    .spotlight-painting-service .form-wrap {
    margin: -3rem auto 0;
    position: relative;
    right: auto;
    top: auto;
    width: 78%}
}
.spotlight-painting-service .form-wrap .wrap {
    background: #fff;
    border-radius: 4px;
    box-shadow: 10px 40px 50px rgba(0, 0, 0, .2);
    padding: 1.5rem;
}

.spotlight-painting-service .form-wrap .wrap .sub-head {
    font-size: 18px;
    font-family:  'Montserrat',  sans-serif;
    
    text-transform:  initial;
}


@media(min-width:767px){
    .spotlight-painting-service .form-wrap .wrap h3 {
        font-size: 24px;
    }
    #col-container{
        justify-content: center;
    }
    #col-container .col{
        width: 33%;
        
    }
    #col-container .wrap{
        min-height: 358px;
    } 
    
    .wrap p{
        font-size: 19px;
    }
    .section-header p{
        font-size: 19px;
    }
    
}

.wrap p{
    font-size: 19px;
}

.main-footer .social-links-wrap ul{

    column-gap: 20px;
}
.contact-at{
    font-size: 16px;
    font-weight: 700;
}
.what{
    text-align: center;
    /* font-family: 'Montserrat';
    font-size: 40px; */
margin: 20px;
}
.why-us-boxes-wrap .wrap .title{
    font-size: 25px;
    color: black;
}


.error-btn{
    background-color: #4CAF50; 
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; 
    transition-duration: 0.4s;
}
.error-btn:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  }


/*# sourceMappingURL=main.css.map */

