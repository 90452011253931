.four-grid-carousel .slides
{
  float: left;
}

@media (max-width: 767px)
{
.four-grid-carousel .slides {
    width: 50%;
    margin-bottom: 15px;
}
}
@media (max-width: 480px)
{
.four-grid-carousel .slides {
    width: 50%;
    margin-bottom: 15px;
}
}
.d-none 
{
  display: none;
}
.visv-0
{
  visibility: hidden;
}
.fadeOut{
  opacity:0;
  width:0;
  height:0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}
.fadeIn{
  opacity:1;
  width:100px;
  height:100px;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}