*{
    box-sizing:border-box;
   /* outline:1px solid ;*/
  }
  
  .thank_you_container{
    height: 100vh;
    width: 100%;
    /* background-color: #0b0202; */
  }
  
  .wrapper-1{
    width:100%;
    height:100vh;
    display: flex;
  flex-direction: column;
  }
  .wrapper-2{
    padding :30px;
    text-align:center;
    background-color: black;
  }
  .thank_you_header{
      font-family: 'montserrat';
    font-size:4.5em;
    letter-spacing:3px;
    /* color:#5892FF ; */
    color: #f1b72f;
    margin:0;
    margin-bottom:20px;
  }
  .wrapper-2 p{
    margin:0;
    font-size:1.3em;
    color:#aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
    font-weight: 700;
  }
  .go-home{
    color:#0b0202;
    /* background:#5892FF; */
    background-color: #f1b72f;
    border:none;
    padding:10px 50px;
    margin:30px 0;
    border-radius:30px;
    text-transform:uppercase;
    font-weight: 700;
    box-shadow: 0 10px 16px 1px rgb(47, 48, 49);
  }
  .footer-like{
    margin-top: auto; 
    background:#1e1f21;
    padding:6px;
    text-align:center;
  }
  .footer-like p{
    margin:0;
    padding:4px;
    color:#c2c4c7;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }
  .footer-like p a{
    text-decoration:none;
    color:#5892FF;
    font-weight:600;
  }
  
  @media (min-width:360px){
    h1{
      font-size:4em;
    }
    .go-home{
      margin-bottom:20px;
    }
  }
  
  @media (min-width:600px){
    .content{
    max-width:1000px;
    margin:0 auto;
  }
    .wrapper-1{
    height: initial;
    max-width:620px;
    /* margin:0 auto; */
    /* margin-top:50px; */
    /* box-shadow: 4px 8px 40px 8px rgba(238, 255, 88, 0.2); */
    box-shadow: 4px 8px 40px 8px   #f1b72f;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%,-50%);
  }
    
  }